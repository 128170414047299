<template>
  <div class="page-templates">
    <h2 class="p-title">已关注</h2>
    <yos-tab-nav :list="tabNav" />
    <div class="p-card">
      <!-- <ul class="list">
        <ol>选择公众号</ol>
        <li
          v-for="item in authorizers"
          :key="item.authorizerAppid"
          :class="item.authorizerAppid === $route.query.appid ? 'active' : '' "
          @click="onTabChange(item.authorizerAppid)"
        >
          {{ item.nickName }}
        </li>
      </ul> -->
      <!-- <div class="cell">
      </div> -->
      <!-- <yos-button class="yos-button-primary" to="/fun/template_libraries/new">批量添加</yos-button> -->
      <yos-button class="yos-button-primary" @click="onSync">同步数据</yos-button>
      <form class="p-search-bar" @submit.prevent="onSubmit">
        <span>公众号：</span>
        <select v-model="appid" class="yos-select" @change="onTabChange">
          <option
            v-for="item in authorizers"
            :key="item.authorizerAppid"
            :value="item.authorizerAppid"
          >
          {{ item.nickName }}
          </option>
        </select>
        <span>用户：</span>
        <yos-input
          v-model.trim="keyword"
          placeholder="搜索用户昵称"
        />
        <yos-button class="yos-button-primary" type="submit">搜索</yos-button>
        <yos-button @click="onReset">重置</yos-button>
      </form>
      <table cellspacing="0" class="m-table">
        <thead>
          <tr>
            <th>序号</th>
            <th>用户</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in dataList" :key="item.authorizerAppid">
            <td>{{ idx + 1 }}</td>
            <td>{{ item.nickname }}</td>
          </tr>
        </tbody>
      </table>
      <yos-pager
        :baseUrl="baseUrl"
        :totalPage="totalPage"
        @change="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import YosTabNav from '../../components/other/tab-nav'
import YosPager from '../../components/other/yos-pager'
import { reactive, toRefs } from 'vue'
import { useServer, usePage, useTabNav, useToast } from '../../hook'
import { useRoute, useRouter } from 'vue-router'
import {
  getAuthorizers,
  getAuthorizerUsers
} from '../../data'
import { serialize, getPageNavs } from '../../util'

export default {
  components: {
    YosTabNav,
    YosPager
  },
  setup() {
    const toast = useToast()
    const route = useRoute()
    const router = useRouter()
    const nav = getPageNavs('manage')
    const state = reactive({
      keyword: route.query.keyword || '',
      authorizer: route.query.authorizer || '',
      platform: parseInt(route.query.platform) || 1,
      totalPage: -1,
      dataList: [],
      authorizers: [],
      appid: route.query.appid || ''
    })

    const { page, baseUrl } = usePage('/manage/follow')

    const setUserListParams = useServer(getAuthorizerUsers, ({ dataList, totalPage, msg }, { sync }) => {
      state.dataList = dataList
      state.totalPage = totalPage
      sync === 1 && toast(msg)
    })

    const setAuthorizersParams = useServer(getAuthorizers, ({ authorizers = [] }, { page }) => {
      const current = authorizers.find(item => item.authorizerAppid === state.appid)
      state.authorizers = authorizers

      if (current === undefined && authorizers.length > 0) {
        state.appid = authorizers[0].authorizerAppid
        router.replace(serialize(baseUrl, { appid: state.appid }))
      }

      setUserListParams({ keyword: state.keyword, authorizerAppid: (current || authorizers[0]).authorizerAppid, page })
    })

    const onSync = () => {
      setUserListParams({ keyword: '', sync: 1, authorizerAppid: state.appid, page: 1 })
    }

    const onTabChange = (event) => {
      const appid = event.target.value
      state.keyword = ''
      router.replace(serialize(baseUrl, { keyword: '', appid, page: 1 }))
      setUserListParams({ keyword: '', authorizerAppid: appid, page: 1 })
    }

    const onPageChange = (page) => {
      setUserListParams({ keyword: state.keyword, authorizerAppid: state.appid, page })
    }

    const onSubmit = () => {
      const params = { keyword: state.keyword, authorizerAppid: state.appid, page: 1 }
      router.replace(serialize(baseUrl, params))
      setUserListParams(params)
    }

    const onReset = () => {
      state.keyword = ''
      onSubmit()
    }

    setAuthorizersParams({status: 1, size: 1000})

    return {
      tabNav: useTabNav(nav),
      ...toRefs(state),
      page,
      baseUrl,
      onSync,
      onTabChange,
      onPageChange,
      onSubmit,
      onReset
    }
  }
}
</script>

<style lang="postcss">
.page-templates {
  .list {
    width: 200px;
    margin-right: 10px;
    border: 1px solid #e7e7eb;

    ol {
      line-height: 40px;
      background-color: #f6f8f9;
      color: #9a9a9a;
      padding: 0 10px;
    }

    li {
      border-top: 1px solid #e7e7eb;
      line-height: 40px;
      padding: 0 10px;
      cursor: pointer;

      &.active {
        color: #1aad19;
      }
    }
  }

  .m-table {
    margin-top: 0;
  }
}
</style>
